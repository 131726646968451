<template>
  <v-app>
    <v-main class="px-4 py-4">
      <slot></slot>
    </v-main>
  </v-app>
</template>

<script>
export default {};
</script>

<style></style>
