import { computed, ref } from "@vue/composition-api";

const route = ref({});

const setRoute = (r) => (route.value = r);
const currentRoute = computed(() => route.value);

export function useRouter() {
  return {
    setRoute,
    currentRoute,
  };
}
