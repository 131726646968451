<template>
  <component :is="resolveLayoutVariant">
    <router-view class="px-md-16 px-sm-1"></router-view>
  </component>
</template>

<script>
import { computed } from "@vue/composition-api";
import { useRouter } from "./composables/router";
import LayoutBlank from "@/layouts/LayoutBlank.vue";
import LayoutContent from "@/layouts/LayoutContent.vue";

export default {
  name: "App",
  components: {
    LayoutBlank,
    LayoutContent,
  },
  setup() {
    const { currentRoute } = useRouter();
    const resolveLayoutVariant = computed(() => {
      if (currentRoute.value.meta.layout == "blank") return "layout-blank";
      if (currentRoute.value.meta.layout == "content") return "layout-content";
      return "layout-blank";
    });
 
    return {
      resolveLayoutVariant,
    };

  },
};
</script>

<style>
body {
  overflow: hidden !important;
  /* Hide scrollbars */
}

::-webkit-scrollbar {
  width: 2px;
  display: none;
}
</style>